/*---------------------------
	Fonts
----------------------------*/

@font-face {
  font-family: "Godo";
  src: url("../fonts/GodoM.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Godo";
  font-style: normal;
  font-weight: 500;
  src: local("Godo Regular"), url("../fonts/GodoM.otf") format("opentype");
}

@font-face {
  font-family: "Godo";
  font-style: normal;
  font-weight: 600;
  src: local("Godo SemiBold"), url("../fonts/GodoM.otf") format("opentype");
}

@font-face {
  font-family: "Godo";
  font-style: normal;
  font-weight: 700;
  src: local("Godo Bold"), url("../fonts/GodoB.otf") format("opentype");
}

// fonts
$base-font-size: 15;
$base-font: "Godo";
$heading-font: "Godo";
$heading-font-color: #070143;

// color
$body-color-dark: #070143;
$body-color: #fff;

$theme-primary-color: #202d90;
// $theme-primary-color: #e3b576;
$theme-primary-color-s2: #071e93;
$theme-text-color: #6e6e6e;
$theme-text-color-light: #676767;

$border-color: #004744;
$border-color-light: #004744;

$dark-gray: #070143;
$white: #fff;
$black: #000;
$heading-color: $dark-gray;
$text-color: #6e6e6e;
