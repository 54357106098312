/* 1.1	Theme Reset Style */
html {
  font-size: $base-font-size + px;
}
:root {
  scroll-behavior: unset;
}
body {
  font-family: $base-font;
  background-color: $body-color;
  overflow-x: hidden;
  @media screen and (max-width: 960px) {
    font-size: ($base-font-size -1) + px;
  }
}

p {
  font-size: $base-font;
  line-height: 1.8em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  font-weight: 500;
  color: $heading-font-color;
}

button:hover,
input:hover,
select:hover,
textarea:hover {
  outline: none;
}
