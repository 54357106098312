.loading-modal {
  @keyframes loading-icon {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .loading-icon div {
    position: absolute;
    width: 120px;
    height: 120px;
    border: 20px solid #18298f;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .loading-icon div {
    animation: loading-icon 1.25s linear infinite;
    top: 100px;
    left: 100px;
  }
  .loading {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .loading-icon {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .loading-icon div {
    box-sizing: content-box;
  }
}

.right-menu-modal {
  .right-menu {
    width: 85vw;
    height: 100%;
    max-width: 400px;
    display: flex;
    flex-flow: column;
    background-color: #fff;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      img {
        width: 120px;
      }

      svg {
        font-size: 20px;
      }
    }

    .contents {
      padding: 12px 20px 0px;
      height: 100%;

      .menu-item {
        border-bottom: 1px solid rgb(241, 243, 245);
        margin-bottom: 22px;
        position: relative;

        p.title {
          color: rgb(136, 142, 148);
          font-weight: 700;
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 22px;
        }
        p.text {
          color: rgb(0, 0, 0);
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 22px;
        }
      }
    }

    .footer {
      .login-button {
        border-bottom: 1px solid rgb(241, 243, 245);
        padding: 12px 20px;

        button {
          height: 44px;
          border-radius: 4px;
          background-color: rgb(50, 54, 58);
          border-width: 1px;
          width: 100%;
          color: rgb(255, 255, 255);
        }
      }

      .sub-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 4px 8px;

        .menu {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;

          p {
            color: rgb(72, 79, 84);
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            padding: 12px;
          }
        }
      }
    }
  }
}

.simple-right-modal {
  .simple-menu {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    position: fixed;
    inset: 0 auto auto 0;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);
    transform: translate(calc(1440px - 100%), 58px);

    img {
      width: 130px;
      padding: 20px 15px;
    }

    p {
      padding: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #32363a;
      letter-spacing: 0;
      line-height: 20px;
    }

    div.hr {
      width: 100%;
      height: 1px;
      margin: 10px 0;
      background-color: #e9ecef;
    }

    button {
      width: 100%;
      padding: 10px 12px;
      color: #fff;
      background-color: #005a54;
      border-radius: 2px;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 12px;
    }
  }
}

.alert-modal {
  transition: opacity 3s ease-out;
  opacity: 1;
}
