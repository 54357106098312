.theme-btn {
  background: $theme-primary-color;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 15px 45px;
  border: 0;
  border-radius: 6px;
  text-transform: capitalize;
  -webkit-backface-visibility: hidden;
  z-index: 1;
  position: relative;
  font-size: 16px;
  font-size: calc-rem-value(16);
  border-radius: 40px;

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    background: darken($theme-primary-color, 8%);
    transition: opacity 0.5s ease-out;
    opacity: 0;
    z-index: -1;
  }

  &:hover,
  &:focus,
  &:active {
    background: darken($theme-primary-color, 5%);
  }

  &:hover:after {
    opacity: 1;
  }

  @include media-query(991px) {
    font-size: 18px;
    font-size: calc-rem-value(18);
  }

  @include media-query(767px) {
    padding: 12px 18px;
    font-size: 14px;
  }
}

button.download-btn {
  padding: 8px 15px;
  color: #1c193f;
  background: #f3f3f3;
  font-size: 15px;
  border-radius: 3px;
  font-weight: 600;
  border: 0;
  outline: 0;

  &:hover {
    background: #17181a;
    color: #fff;
  }
}
