* {
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
}
*,
:after,
:before {
  box-sizing: border-box;
}
:root {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  cursor: default;
  line-height: 1.5;
  overflow-wrap: break-word;
  word-break: break-word;
  tab-size: 4;
}
html,
body {
  width: 100%;
  height: 100%;
}
img,
picture,
video,
canvas,
svg {
  // display: block;
  max-width: 100%;
}
button {
  background: none;
  border: 0;
  cursor: pointer;
}
a {
  text-decoration: none;
}
// a:visited {color:#609}
// a:hover {text-decoration:underline}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
ul,
ol {
  list-style: none;
}
ol,
ul,
li {
  margin: 0;
  padding: 0;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}
p {
  margin: 0;
}
